import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Swal from 'sweetalert2';
import logo from '../images/Blue.svg';
import careerImg from '../images/career.svg';

const CareerForm = ({ position }) => {
  const [fileName, setFileName] = React.useState('');
  const onFireAlert = () => {
    Swal.fire({
      icon: 'success',
      title: `Thanks! We'll contact you soon.`,
      showConfirmButton: false,
      timer: 1500,
    });
  };
  return (
    <div className='career-form'>
      <div className='columns'>
        <div className='column is-hidden-mobile'>
          <center>
            <img
              src={logo}
              alt='logo'
              width='200'
              style={{ marginTop: 27, marginRight: 30 }}
            />

            <img
              src={careerImg}
              alt='look@yourself'
              width='100%'
              style={{
                marginTop: '30%',
                width: '350px',
                marginRight: 30,
              }}
            />
            <p
              style={{
                marginTop: 5,
                fontFamily: 'Muli',
                fontSize: 18,
                fontWeight: 600,
              }}
            >
              See yourself grow with us
            </p>
          </center>
        </div>
        <div className='column '>
          <img
            src={logo}
            alt='logo'
            width='300'
            style={{ marginBottom: '1em' }}
            className='is-hidden-desktop is-hidden-tablet'
          />
          <p className='subtext-width-career' style={{ marginTop: '20px' }}>
            Stay connected by joining our Talent Network.
          </p>
          <form
            action='https://formsubmit.co/a1756621ef1b003d471d05687a018818'
            method='POST'
            onSubmit={onFireAlert}
            enctype='multipart/form-data'
          >
            <input type="hidden" name="_captcha" value="false"></input>
            <input
              type='hidden'
              name='_next'
              value='https://gonuclei.com/careers'
            />
            <input type='hidden' name='_template' value='table' />
            <input
              type='hidden'
              name='_autoresponse'
              value="Thanks for showing interest, We'll contact you soon if your profile matches our requirements"
            />
            <input
              type='hidden'
              name='_subject'
              value='New Careers form Submission!'
            />
            <input type='hidden' name='position' value={position} />
            <div className='columns'>
              <div className='column'>
                <input
                  className='input'
                  type='text'
                  placeholder='First Name *'
                  style={{ background: '#F9F9F9', boxShadow: 'none' }}
                  name='firstName'
                  required
                />
              </div>
              <div className='column'>
                <input
                  className='input'
                  type='text'
                  placeholder='Last Name *'
                  style={{ background: '#F9F9F9', boxShadow: 'none' }}
                  name='lastName'
                  required
                />
              </div>
            </div>
            <div className='columns'>
              <div className='column'>
                <input
                  className='input'
                  type='email'
                  placeholder='Email *'
                  style={{ background: '#F9F9F9', boxShadow: 'none' }}
                  name='email'
                  required
                />
              </div>
              <div className='column'>
                <input
                  className='input'
                  type='number'
                  placeholder='Phone *'
                  name='phone'
                  style={{ background: '#F9F9F9', boxShadow: 'none' }}
                  required
                />
              </div>
            </div>
            <div className='columns'>
              <div className='column'></div>
            </div>
            <div className='columns'>
              <div className='column'>
                <div className='file has-name'>
                  <label className='file-label'>
                    <input
                      className='file-input'
                      type='file'
                      name='resume'
                      onChange={e => setFileName(e.target.files[0].name)}
                      accept='image/png, image/jpeg, application/pdf'
                      required
                    />
                    <span className='file-cta'>
                      <span className='file-icon'>
                        <FontAwesomeIcon icon={faUpload} color='#0DA3FF' />
                      </span>
                      <span className='file-label'>Resume *</span>
                    </span>
                    <span className='file-name'>
                      {fileName !== '' ? fileName : 'only .pdf/.jpeg/png'}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div className='columns'>
              <div className='column'>
                <input
                  className='input'
                  style={{ background: '#F9F9F9', boxShadow: 'none' }}
                  type='number'
                  placeholder='Notice Period in Days *'
                  name='NoticePeriod'
                  required
                />
              </div>
              <div className='column is-two-fifths'>
                <input
                  className='input'
                  type='number'
                  style={{ background: '#F9F9F9', boxShadow: 'none' }}
                  placeholder='Expected CTC'
                  name='expectedCTC'
                />
              </div>
            </div>
            <div className='columns'>
              <div className='column'>
                <input
                  className='input'
                  style={{ background: '#F9F9F9', boxShadow: 'none' }}
                  type='url'
                  placeholder='Linkedin Profile '
                  name='linkedinProfile'
                />
              </div>
            </div>{' '}
            <div className='columns'>
              <div className='column'>
                <input
                  className='input'
                  style={{ background: '#F9F9F9', boxShadow: 'none' }}
                  type='url'
                  placeholder='Link to your greatest work'
                  name='extraLink'
                />
              </div>
            </div>
            <center>
              <button
                type='submit'
                className='button'
                style={{ marginTop: 10 }}
              >
                <span className='button-text'>Submit</span>
              </button>
            </center>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CareerForm;
