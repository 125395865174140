import React, { useState, useEffect } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql } from 'gatsby';
import Modal from 'react-modal';
import CareerForm from '../components/CareerForm';
import SEO from '../components/seo';
import { Helmet } from 'react-helmet';
import CareersBottom from '../components/CareersBottom';
import { format } from 'date-fns';
import Layout from '../components/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export const query = graphql`
  query($slug: String!) {
    contentfulCareersJobPosting(slug: { eq: $slug }) {
      title
      publishedDate
      body {
        raw
      }
    }
  }
`;

const CareerPost = props => {
  const [openModal, setopenModal] = useState(false);
  const options = {
    renderNode: {
      'embedded-asset-block': node => {
        const alt = node.data.target.fields.title['en-US'];
        const url = node.data.target.fields.file['en-US'].url;
        return <img alt={alt} src={url} />;
      },
    },
  };
  const sendMail = () => {
    // window.open(
    //   `mailto:people@gonuclei.com?subject=Job Application regarding ${props.data.contentfulCareersJobPosting.title}`
    // );
    setopenModal(true);
  };

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.8)',
      display: 'flex',
      'align-items': 'center',
    },
    content: {
      top: '55%',
      left: '50%',
      right: 'auto',
      zIndex: '1000',
      bottom: 'auto',
      maxHeight: '100%',
      maxWidth: '120%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#f9f9f9',
    },
  };

  return (
    <React.Fragment style={{ backgroundColor: '#f9fcff' }}>
      <Modal
        closeTimeoutMS={900}
        isOpen={openModal}
        style={customStyles}
        onRequestClose={() => setopenModal(false)}
        contentLabel='Lets Talk'
      >
        <FontAwesomeIcon
          onClick={() => setopenModal(false)}
          icon={faTimes}
          style={{
            color: '#666',
            float: 'right',
            cursor: 'pointer',
          }}
        />
        <CareerForm position={props.data.contentfulCareersJobPosting.title} />
      </Modal>
      <Layout isCareer={true}>
        <Helmet>
          {' '}
          <script
            key={`gatsby-plugin-hotjar`}
            dangerouslySetInnerHTML={{
              __html: `
              (function(h,o,t,j,a,r){
                  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                  h._hjSettings={hjid:1995427,hjsv:6;
                  a=o.getElementsByTagName('head')[0];
                  r=o.createElement('script');r.async=1;
                  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                  a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')
          `,
            }}
          />
        </Helmet>
        <SEO
          title={props.data.contentfulCareersJobPosting.title}
          description={props.data.contentfulCareersJobPosting.title}
          article
        />
        <section
          className='section section-career-template'
          style={{ marginBottom: '1px', paddingBottom: '1px' }}
        >
          <div className='content'>
            <h1>
              {props.data.contentfulCareersJobPosting.title}
              <button
                className='button button-career'
                onClick={() => sendMail()}
              >
                {' '}
                <span className='button-text'>Apply Now</span>
              </button>
            </h1>
            <span className='meta'>
              <p>
                Posted on{' '}
                {format(
                  new Date(
                    props.data.contentfulCareersJobPosting.publishedDate
                  ),
                  'iii do MMM, yyyy'
                )}
              </p>
            </span>
            {props.data.contentfulCareersJobPosting.body.raw}
          </div>
        </section>
        <CareersBottom isCareerTemplate={true} />
      </Layout>
    </React.Fragment>
  );
};

export default CareerPost;
