import React from 'react';

const CareersBottom = ({ isCareerTemplate }) => {
  return (
    <section
      className='section'
      style={{
        marginTop: '30px',
        backgroundImage: 'linear-gradient(to bottom, #ffffff 22%, #dff3ff)',
        zIndex: '1',
        fontFamily: 'Open Sans',
      }}
    >
      <div className='columns is-mobile'>
        <div
          className='column is-size-2 is-size-4-mobile'
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            verticalAlign: 'center',
          }}
        >
          <p className='bottom-bar-text'>
            {isCareerTemplate ? (
              <p>
                Interested? <br />
                Please send an email with a latest copy of your resume to{' '}
              </p>
            ) : (
              'Don’t see a role that fits?'
            )}{' '}
          </p>
          <center>
            {isCareerTemplate ? (
              <a
                href='mailto:people@gonuclei.com?subject=Opportunity in Nuclei'
                className='emailus'
              >
                people@gonuclei.com
              </a>
            ) : (
              <a href='mailto:people@gonuclei.com' className='emailus'>
                Email us at people@gonuclei.com
              </a>
            )}
          </center>
        </div>
      </div>
    </section>
  );
};

export default CareersBottom;
